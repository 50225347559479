<template>
    <div class="app-admin-page application-page">
        <ApplicationHeader name="scriptText.scriptList" :count="count"/>
        <ApplicationFilter class="mb-1-a" v-bind="filterAttrs" v-on="filterListeners"/>
        <AppNavbar class="mb-6-a" :value="filter[isChatKey]" :items="scriptTypes" @input="onFilterInput(isChatKey, $event)"/>
        <ApplicationContent v-bind="contentAttrs" v-on="contentListeners" sekeleton :skeletonList="skeletonList"/>
        <ApplicationModal v-if="modalVisible" v-bind="modalAttributes" v-on="modalListeners" icon="speech-bubble" name="scriptText.addScript" ref="modal"/>
    </div>
</template>
<script>
import {get} from '../../helpers/api';
import {CONTENT_ITEMS_SIZES} from "../../components/admin/ContentItems";
import ApplicationFilter from "../../components/admin/ApplicationFilter";
import ApplicationHeader from "../../components/admin/ApplicationHeader";
import ApplicationModal from "../../components/modals/ApplicationModal";
import {
    ScriptPage,
    ModalFormController,
    ModalController,
    FormInputElement,
    FormInputList,
    ContentLoader
} from '../../services/ScriptPage';
import AppNavbar from "../../components/partials/AppNavbar";
import clickaway from '../../directives/clickaway';
import {getApplicationDefaultMixin, getApplicationFilterMixin, getApplicationModalMixin} from "../../mixins/ScriptPage";
import ApplicationContent from "../../components/admin/ApplicationContent";

const isChatKey = 'is_chat';
const filter = {
    skip: 0,
    limit: 20,
    script_category_id: null,
    is_need_question: null,
    [isChatKey]: 0
}

class ScriptContentLoader extends ContentLoader {
    constructor() {
        super('/script-texts');
    }
    transformData(form) {
        let data = {...form};
        delete data[isChatKey];
        if(!data.is_question) {
            delete data.yes_text_ru;
            delete data.yes_text_uz;
            delete data.no_text_ru;
            delete data.no_text_uz;
        }
        data.is_question = +data.is_question;
        return data;
    }
}

export default {
    name: "ScriptText",
    components: {
        ApplicationContent,
        AppNavbar,
        ApplicationModal,
        ApplicationHeader,
        ApplicationFilter,
    },
    directives: {clickaway},
    mixins: [
        getApplicationDefaultMixin(new ScriptPage(
            new ScriptContentLoader(),
            filter,
            new ModalFormController(
                new FormInputList('1', '', '', '', [
                    new FormInputElement('id', 'id', '', 'hidden'),
                    new FormInputElement('script_category_id', 'common.category', '', 'select', [], [['required']], {resetOn: isChatKey}),
                    new FormInputElement('text_ru', 'common.form.scriptTextRu', '', 'textarea', null, [['required']]),
                    new FormInputElement('text_uz', 'common.form.scriptTextUz', '', 'textarea', null, [['required']]),
                    new FormInputElement('is_question', 'common.form.isScriptRequireQuestion', false, 'switch'),
                    new FormInputList('yes_description', 'common.yes', '', 'block', [
                        new FormInputElement('yes_text_ru', 'common.form.descriptionRu', '', 'textarea', null, [['requiredIf', 'is_question']], {resetOn: 'is_question'}),
                        new FormInputElement('yes_text_uz', 'common.form.descriptionUz', '', 'textarea', null, [['requiredIf', 'is_question']], {resetOn: 'is_question'}),
                    ], [], {dependsOn: 'is_question'}),
                    new FormInputList('no_description', 'common.no', '', 'block', [
                        new FormInputElement('no_text_ru', 'common.form.descriptionRu', '', 'textarea', null, [['requiredIf', 'is_question']], {resetOn: 'is_question'}),
                        new FormInputElement('no_text_uz', 'common.form.descriptionUz', '', 'textarea', null, [['requiredIf', 'is_question']], {resetOn: 'is_question'}),
                    ], [], {dependsOn: 'is_question'}),
                    new FormInputElement(isChatKey, 'common.form.scriptForChats', false, 'switch'),
                ])
            ),
            new ModalController()
        )),
        getApplicationFilterMixin(filter),
        getApplicationModalMixin()
    ],
    data() {
        return {
            isChatKey,
            filterItems: [
                {
                    type: 'drop',
                    name: 'scriptText.requiresQuestion',
                    key: 'is_need_question',
                    items: [
                        {
                            name: 'common.all',
                            value: null
                        },
                        {
                            name: 'scriptText.requiresQuestion',
                            value: 1,
                        }, {
                            name: 'scriptText.notRequiresQuestion',
                            value: 0
                        }
                    ]
                },
                {
                    type: 'drop',
                    name: 'common.category',
                    key: 'script_category_id',
                    items: []
                },
                {
                    type: 'spacer',
                    key: 'a'
                },
                {
                    name: 'common.add',
                    key: 'add',
                    type: 'button'
                }
            ],
            script_categories: [],
            scriptTypes: [
                {
                    name: 'scriptText.scriptForCall',
                    value: 0
                },
                {
                    name: 'scriptText.scriptForChats',
                    value: 1
                }
            ],
        }
    },
    mounted() {
        this.loadScriptCategories();
        this.page.on('input', this.filterFormModel);
    },
    beforeDestroy() {
        this.page.off('input', this.filterFormModel);
    },
    methods: {
        transformEditData(item) {
            const data = {
                id: item.id,
                text_ru: item.text_ru,
                text_uz: item.text_uz,
                is_question: !!item.is_need_question,
                [isChatKey]: !!item.script_category.isChat,
                script_category_id: item.script_category_id,
            }
            if(item.is_need_question) {
                data.yes_text_ru = item.yes.text_ru;
                data.yes_text_uz = item.yes.text_uz;
                data.no_text_ru = item.no.text_ru;
                data.no_text_uz = item.no.text_uz;
            }
            return data
        },
        parseItemData(item, idx) {
            return [
                {
                    name: 'common.table.number',
                    value: (idx + 1) + this.filter.skip,
                    size: CONTENT_ITEMS_SIZES[0],
                    skeleton: this.loading,
                    skeletonHeight: "20px"
                },
                {
                    name: 'common.table.category',
                    value: item.script_category.name,
                    size: CONTENT_ITEMS_SIZES[2],
                    skeleton: this.loading,
                    skeletonHeight: "20px"
                },
                {
                    name: 'common.table.question',
                    value: item.text_ru,
                    size: CONTENT_ITEMS_SIZES[3],
                    skeleton: this.loading,
                    skeletonHeight: "20px"
                },
                {
                    name: 'common.table.requiresQuestion',
                    value: item.is_need_question ? 'common.yes' : 'common.no',
                    tag: true,
                    size: CONTENT_ITEMS_SIZES[1],
                    active: !!item.is_need_question,
                    skeleton: this.loading,
                    skeletonHeight: "20px"
                },
            ]
        },

        filterFormModel(keys) {
            if(keys.includes('formModel')) {
                this.filterRendererCategory();
            }
        },

        filterRendererCategory() {
            this.page.setFormRenderElement({
                items: this.script_categories.filter(cat => cat.isChat === this.formModel[isChatKey])
            }, 'script_category_id');
        },
        loadScriptCategories() {
            get(`/script-categories`)
                .then((response) => {
                    if (response.data.success) {
                        this.script_categories = response.data.data.data.map((item) => {
                            item.name = item.isChat ? '(Чаты) ' + item.name : item.name;
                            item.value = item.id;
                            return item;
                        });

                        const category = this.filterItems.findIndex(k => k.key === 'script_category_id');
                        this.filterItems.splice(category, 1, {
                            ...this.filterItems[category],
                            items: [
                                {
                                    name: 'common.all',
                                    value: null
                                },
                                ...this.script_categories
                            ]
                        })
                        this.filterRendererCategory();
                    }
                })
        },
    },

    computed: {
        skeletonList() {
            const list = []
            for (let index = 0; index < 10; index++) {
                list.push({
                    id: index,
                    script_category: {},
                    skeleton: true,
                })
            }
            return list
        }
    }
}
</script>
