<template>
    <div class="app-navbar">
        <div v-for="item in items" :key="item.value" class="app-navbar__item" :class="{'app-navbar__item--active': value === item.value}" @click="$emit('input', item.value)">{{ $t(item.name) }}</div>
    </div>
</template>
<script>
export default {
    name: 'AppNavbar',
    props: {
        value: [String, Number, Boolean],
        items: Array,
    }
}
</script>
<style lang="scss">
.app-navbar {
    border-bottom: 1px solid #E1E1E1;
    display: flex;
    &__item {
        transition: color .25s;
        cursor: pointer;
        user-select: none;
        position: relative;
        margin-right: 82px;
        margin-bottom: -1px;
        padding-top: 24px;
        font-weight: 700;
        font-size: 20px;
        line-height: 20px;
        letter-spacing: 0.005em;
        color: #717171;
        &:after {
            transition: transform .25s;
            transform-origin: 0 0;
            transform: scaleX(0);
            content: '';
            display: block;
            width: 100%;
            height: 2px;
            margin-top: 22px;
            background-color: var(--color-primary);
        }
        &--active {
            color: #212121;
            &:after {
                transform: scaleX(1);
            }
        }
    }
}
</style>
